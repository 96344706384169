<template>
  <div>
    <template v-if="sectionType === 'card'">
      <section-card v-for="(section, sectionIndex) in sections" :key="'section-' + sectionIndex" :title="section.title" :show-title="section.showTitle" :inputs="section.inputs" @removeSection="$emit('removeSection', sectionIndex)" @change="(val) => $emit('change', { sectionIndex, val})" style="margin:1em 0"></section-card>
    </template>
    <template v-else-if="sectionType === 'stepper'">
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <v-stepper-step v-for="(section, sectionIndex) in sections" :key="'stepper-' + sectionIndex" :step="sectionIndex + 1" editable>{{ section.showTitle ? section.title : '' }}</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="(section, sectionIndex) in sections" :key="'section-' + sectionIndex" :step="sectionIndex + 1">
            <section-card :title="section.title" :show-title="section.showTitle" :inputs="section.inputs" @removeSection="$emit('removeSection', sectionIndex)" @change="(val) => $emit('change', { sectionIndex, val})"></section-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template v-else-if="sectionType === 'stepper-v'">
      <v-stepper v-model="currentStep" vertical>
        <template v-for="(section, sectionIndex) in sections">
          <v-stepper-step :key="'stepper-' + sectionIndex" :step="sectionIndex + 1" editable>{{ section.showTitle ? section.title : '' }}</v-stepper-step>
          <v-stepper-content :key="'section-' + sectionIndex" :step="sectionIndex + 1">
            <section-card :title="section.title" :show-title="section.showTitle" :inputs="section.inputs" @removeSection="$emit('removeSection', sectionIndex)" @change="(val) => $emit('change', { sectionIndex, val})"></section-card>
          </v-stepper-content>
        </template>
      </v-stepper>
    </template>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
export default {
  components: {
    SectionCard: () => import('@/components/forms/editor/section-card')
  },
  props: {
    sectionType: {
      type: String,
      required: true
    },
    sections: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const currentStep = ref(1)
    return {
      currentStep
    }
  }
}
</script>
